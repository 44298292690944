import { Controller } from "stimulus";
import { Alert } from "bootstrap";

export default class extends Controller {
  connect() {
    setTimeout(() => {
      const alertNode = this.element;
      const alertEl = new Alert(alertNode);

      alertEl.close();
    }, 5000)
  }
}
