import { Controller } from "stimulus";
import { Toast } from "bootstrap";

export default class extends Controller {
  connect() {
    const toastNode = this.element;
    const toastEl = new Toast(toastNode);

    toastEl.show();
  }
}
